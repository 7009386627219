import React from 'react';
import { useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import { SpinnerIcon } from '@spa-cars/ui';
import {
  Appointment,
  Client,
  Discount,
  Pagination,
  Vehicle,
} from '@spa-cars/models';
import {
  GET_APPOINTMENT_CUSTOM,
  GET_CLIENT_VEHICLES,
  GET_DISCOUNTS_LIST,
} from '../../graphql/queries';
import { Auth, Layout, SeoHead } from '../../components';
import { useUser } from '../../hooks';
import LoggedInHome from '../../components/loggedInHome/LoggedInHome';

function HomeLoggedPage() {
  const [user] = useUser();
  const [auth, setAuth] = React.useState(false);

  const { data, loading } = useQuery<{
    discountPagination: Pagination<Discount>;
  }>(GET_DISCOUNTS_LIST, {
    variables: {
      page: 1,
      perPage: 8,
      filter: {
        active: true,
        show: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    loading: loadingUpcomingAppointments,
    data: dataUpcomingAppointments,
  } = useQuery<{
    getAppointmentsCustom: {
      todaysAppointments: Appointment[];
      nextAppointments: Appointment[];
    };
  }>(GET_APPOINTMENT_CUSTOM, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        userId: user?._id,
      },
    },
  });

  const { loading: loadingVehicles, data: dataVehicles } = useQuery<{
    client: {
      vehicles: Vehicle[];
    };
  }>(GET_CLIENT_VEHICLES, {
    variables: { filter: { _id: (user?.client as Client)?._id } },
    fetchPolicy: 'network-only',
  });

  return (
    <Layout>
      <SeoHead title="Spacars | Inicio" />
      <Auth setAuth={setAuth} />
      {auth && !loading && !loadingUpcomingAppointments && !loadingVehicles ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: [0, 1],
            transition: {
              duration: 0.5,
            },
          }}
        >
          <LoggedInHome
            discounts={data.discountPagination}
            upcomingAppointments={
              dataUpcomingAppointments?.getAppointmentsCustom
            }
            vehicles={dataVehicles.client.vehicles}
          />
        </motion.div>
      ) : (
        <div className="w-full h-[70vh] flex  opacity-70 z-30">
          <SpinnerIcon className="m-auto w-24 h-24 text-gray-200 animate-spin dark:text-gray-600 fill-primary-300" />
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </Layout>
  );
}

export default HomeLoggedPage;
