import React from 'react';
import { useRouter } from 'next/router';
import { CarsCarousel } from '@spa-cars/ui';
import { Appointment, Discount, Pagination, Vehicle } from '@spa-cars/models';
import Sales from './sales/Sales';
import TodayAppointments from '../appointment/MyAppointments/TodayAppointments';
import UpcomingAppointments from '../appointment/MyAppointments/UpcomingAppointments';
import isoTypo from '../../public/images/isotypo-yellow.png';
import NewCarModalWrapper from '../appointment/CreateAppointment/MyCars/NewCarModal/NewCarModalWrapper';

interface LoggedInHomeProps {
  discounts: Pagination<Discount>;
  upcomingAppointments: {
    todaysAppointments: Appointment[];
    nextAppointments: Appointment[];
  };
  vehicles: Vehicle[];
}

function LoggedInHome({
  discounts,
  upcomingAppointments,
  vehicles,
}: LoggedInHomeProps) {
  const router = useRouter();
  const [myCars, setMyCars] = React.useState(
    vehicles.length > 0 ? vehicles : []
  );
  const [newCarModalIsOpen, setNewCarModalIsOpen] = React.useState(false);

  return (
    <main className="w-full max-w-[1440px] m-auto px-5 pb-10">
      <section className="flex w-full flex-wrap">
        <div className="w-full flex flex-wrap">
          <div className="w-full sm:min-w-[22rem]  lg:w-5/12 xl:w-1/3">
            <TodayAppointments
              todayAppointments={upcomingAppointments?.todaysAppointments}
            />
          </div>
          <div className="w-full  lg:w-7/12 xl:w-2/3">
            <UpcomingAppointments
              upcomingAppointments={upcomingAppointments?.nextAppointments}
            />
          </div>
        </div>
      </section>
      <section className="w-full md:w-1/2">
        <div className="w-full flex my-10 pr-5">
          <img
            className="w-8 h-6 mr-5 mt-1"
            src={isoTypo.src}
            alt="spa-car-logo"
          />
          <div className="pb-2">
            <h2 className="text-xl md:text-2xl text-neutral-400 font-bold ">
              Mis vehículos
            </h2>
            <span className="text-neutral-300 font-light text-md  ">
              Agenda una cita para tus vehículos
            </span>
          </div>
        </div>
        <CarsCarousel
          myCars={myCars}
          setNewCarModalIsOpen={setNewCarModalIsOpen}
          action={(id = '') => {
            if (id) {
              router.push({
                pathname: '/appointment/create',
                query: { carId: id },
              });
            } else {
              router.push('/cars');
            }
          }}
          showHeader={false}
        />
        <NewCarModalWrapper
          isOpen={newCarModalIsOpen}
          setIsOpen={setNewCarModalIsOpen}
          setMyCars={setMyCars}
          myCars={myCars}
        />
      </section>
      <Sales discounts={discounts} />
    </main>
  );
}

export default LoggedInHome;
